<template>
  <div id="ates" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <p class="title is-4 has-text-left">
        {{ ates.length > 0 ? ates[0].schoolClass.name : '' }}
      </p>
      <p class="subtitle is-6"><a @click="removeAll()">Reset Ates</a></p>
      <b-collapse
        class="card"
        :open="index == 12"
        v-for="(ate, index) in ates"
        :key="ate.id"
        @open="setOpen(index)"
      >
        <div
          class="card-header"
          role="button"
          slot="trigger"
          slot-scope="props"
        >
          <p class="card-header-title">{{ ateName(ate.name) }}</p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'arrow-up' : 'arrow-down'"> </b-icon>
          </a>
        </div>
        <div class="card-content">
          <div class="content">
            <b-table :data="ate.ateMarks" :paginated="isPaginated" hoverable>
              <b-table-column
                field="#"
                label="#"
                width="40"
                numeric
                v-slot="props"
              >
                {{ ate.ateMarks.indexOf(props.row) + 1 }}
              </b-table-column>

              <b-table-column label="Name" v-slot="props">{{
                props.row.student.name
              }}</b-table-column>

              <b-table-column label="Score" v-slot="props">{{
                props.row.score
              }}</b-table-column>

              <b-table-column label="Marked" v-slot="props">{{
                props.row.marked
              }}</b-table-column>
            </b-table>
            <div class="is-flex is-justify-content-end">
              <a
                class="is-small has-text-danger"
                @click="initializeRemove(ate)"
                style="font-size: 1.2em; margin-left: 16px"
              >
                <fa-icon icon="trash-alt" size="lg" />
              </a>
            </div>
          </div>
        </div>
      </b-collapse>
    </template>
    <!-- Delete -->
    <app-modal
      id="remove-ate"
      context="remove"
      @remove="remove"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '../../assets/js/notification'
// import appModal from '../../assets/js/app_modal'

export default {
  data() {
    return {
      schoolId: null,
      pageTitle: 'Ates',
      ates: [],
      isPaginated: false,
      id: null,
      ids: null,
      activeTab: 0,
      isOpen: 0,
      index: 0,
      scope: 'one',
    }
  },
  watch: {
    ates(data) {
      if (data.length == 0) {
        this.$router.push(
          `/school/${this.schoolId}/assigned_subject_info/${this.$route.params.school_class_id}`
        )

        this.$buefy.toast.open({
          duration: 3000,
          message: 'No ates for this subject',
          type: 'is-info',
        })
      } else {
        this.$store.commit('setSubMenus', [
          {
            name: 'Assigned Subjects Info',
            route: `/school/${this.schoolId}/assigned_subject_info/${data[0].schoolClass.id}`,
          },
        ])

        this.ids = data.map((item) => item.id)
      }
    },
  },
  methods: {
    setOpen(index) {
      this.index = index
    },
    initializeRemove(ate) {
      this.id = parseInt(ate.id)
      this.openModal()
    },
    removeAll() {
      this.scope = 'all'
      this.openModal()
    },
    remove() {
      if (this.scope == 'one') {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation DeleteAte($id: Int!) {
                deleteAte(input: { id: $id }) {
                  ate {
                    id
                  }
                  errors
                }
              }
            `,
            variables: {
              id: this.id,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.deleteAte.errors,
              'Successfully deleted.'
            ).then(() => {
              this.closeModal()
              this.$apollo.queries.ates.refetch()
            })
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation DeleteAllAtes($ids: JSON!) {
                deleteAllAtes(input: { ids: $ids }) {
                  ate {
                    id
                  }
                  errors
                }
              }
            `,
            variables: {
              ids: this.ids,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.deleteAllAtes.errors,
              'Successfully deleted.'
            ).then(() => {
              this.closeModal()
              this.$router.push(
                `/school/${this.schoolId}/assigned_subject_info/${this.$route.params.school_class_id}`
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
    ateName(name) {
      if (name === 'fa') {
        return 'FIRST ASSIGNMENT'
      } else if (name === 'sa') {
        return 'SECOND ASSIGNMENT'
      } else if (name === 'ft') {
        return 'FIRST TEST'
      } else if (name === 'st') {
        return 'SECOND TEST'
      } else if (name === 'aff') {
        return 'AFFECTIVE DOMAIN'
      } else {
        return 'EXAMS'
      }
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    const assignedSubjectId = parseInt(this.$route.params.id)
    this.$store.commit('setPageTitle', this.pageTitle)

    this.$apollo.addSmartQuery('ates', {
      query: gql`
        query AtesQuery($schoolId: ID!, $assignedSubjectId: ID!) {
          ates(schoolId: $schoolId, assignedSubjectId: $assignedSubjectId) {
            id
            name
            schoolClass {
              id
              name
            }
            ateMarks {
              id
              student {
                id
                name
              }
              score
              marked
            }
          }
        }
      `,
      variables: {
        schoolId: this.schoolId,
        assignedSubjectId: assignedSubjectId,
      },
    })

    this.$apollo.queries.ates.refetch()
  },
}
</script>
